
  .btn-select{
    background: #211F28;
    border-radius: 20px;
    transition: 0.33s all;
  }


  @media screen and (max-width: 600px) {
    .control-panel {
        background: #04020D;
        color: #7F9EBE;
        width: 100%;
    }


    .elements {
      justify-content: space-between;
      padding: 5px;
      display: flex;
      align-items: center;
      border: 1px #7f9ebe59 solid;
      margin: 10px 10px 10px 10px;
      border-radius: 5px;
  }
    .logo{
        width: 75px;
    }
    .logo img{
        filter: invert(0);
        width: 50px;
        padding: 4px 15px 0;
    }
    .route-buttons {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }
    
    .route-buttons .btn {
        padding: 10px;
        margin: 10px;
    }

  }