.user-stats{
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 10px;
    justify-content: space-between;
    margin-bottom: 14px;
    background: #211f28;
    border-radius: 5px;
    box-shadow: 0 0 4px #04020d78;
    font-family: monospace;
}
.right-stats{
    text-align: right;
}

.left-stats{
    text-align: left;
}

.top-verif{
    width: auto;
    position: relative;
    height: 19px;
}
.ava{
    width: 14px;
    padding: 0 5px
    
}
.top-user-header{
    display: flex;
    width: 100;
}
.top-user-element{
    font-size: 14px;
    display: flex;
}
.top-user{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 6px 10px;
    background: #211f28;
    border-radius: 4px;
    margin-bottom: 4px;
    width: 100%;
}
.top-user p{
    margin: 0;
    padding: 0;
}
.user-name{
    display: flex;
    align-items: center;
    width: 100%;
}
.user-data{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.row-name{
    font-size: 11px;
    font-family: monospace;
    font-weight: 600;
    text-transform: uppercase;
    text-shadow: 0 0 4px #916e3b;
}
.user-data div{
    width: 100%;
    text-align: center;
}
.headtable{
    padding: 13px 10px;
}

.top-ten{
    margin-top: 13px;
}