
  
  .rel-enter {
    opacity: 0;
  }
  .rel-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  .rel-exit {
    opacity: 1;
  }
  .rel-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }



@media screen and (max-width: 600px) {
    .graph-panel {
        display: flex;
        flex-direction: column;
        background-color: #04020d;
        color: white;
        /* margin-bottom: 35px; */
      }

    .graph-panel{
        width: 100%;
        padding: 0;
    }
    .pair-info .pair{
        font-size: 16px;
    }
    .p-box p{
        font-size: 12px;
    }
    .right-box div{
        font-size: 12px;
    }
    .graph{
        width: 100%;
    }
    .graph .wrapper{
        width: auto;
        height: 420px;
    }

    .wrapper .price, .wrapper .activity{
        height: 200px;
        margin-bottom: 20px;
    }

    .day-volume-token2{
        font-size: 16px;
        font-weight: 600;
    }
    .wrapper .header{
        font-feature-settings: "pcap", "c2pc";
        text-shadow: -1px -2px 9px #1f74ca56;
        position: absolute;
        z-index: 10000;
        width: 50%;
        font-family: monospace;
        padding: 10px 5px;
        opacity: 0.6;
        font-size: 10px;
    }
    .fixthis{
        transform: translateY(10px);
    }
    .actions-wrap{
        border: 1px solid rgba(100, 150, 200, 0.205);
        box-shadow: 0 0 13px 1px #211f284d;
        border-radius: 4px;
        /* min-height: 250px; */
    }
    .main-graph{
        /* margin-bottom: 40px; */
    }
    .pair-volume-token2{
        padding: 0
    }


    .action-block .element{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px #7f9ebe42 solid;
    }  
    .element img{
        width: 15px;
        padding: 0 1px;
    }

    .blocks{
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        padding: 10px;
        min-height: 375px;
    }
    .action-block{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        /* overflow: hidden; */
        width: 100%;
    }
    .py-5{
        display: flex;
        line-height:15px;
        justify-content: space-between;
        font-size: 14px;
    }
    .element table{
        width: 100%;
    }
    .element .time{
        font-size: 12px;
    }
    .wrapper .price{
        /* transform: translate(10px, 0px); */
        width: 100%;
    }
    .wrapper .activity{
        width: 100%;
    }
    .live-actions-title{
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .graph-head {
        display: flex;
        justify-content: space-between;
        background: #211f28;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
    }
  }