




.res-data{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.balances, .txs{
    padding: 5px 20px 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    background: #211f28;
    border-radius: 5px;
    box-shadow: 0 0 4px #04020d78;
}

.content.left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding-right: 10px;
}
.content.right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
    padding-left: 10px;
}

.content p{
    margin: 0;
    font-family: monospace;
}

.res-data .title{
    font-variant-caps: all-petite-caps;
    margin-bottom: 5px;
}
.content.same{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 10px;
    height: 100%;
    justify-content: flex-end;
}

.graph-box div{
    width: 100%;
    height: 200px
}
.reserves-graph .header{
    font-feature-settings: "pcap", "c2pc";
    text-shadow: -1px -2px 9px #1f74ca56;
    position: absolute;
    font-family: monospace;
    z-index: 10000;
    padding: 5px 0px 0 5px;
    width: 50%;
    opacity: 0.6;
}

.reserves-graph{
    margin-top: 15px;
}

.top-addresses{
    margin-top: 20px
}

.top-body{
    font-size: 14px;
    border-radius: 3px;
    background: #211f28;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-left: 10px;
    margin-bottom: 5px;
    
}

.top-element a{
    text-decoration: underline;
    color: #7f9ebe
}
.top-element{
    padding-bottom: 5px;
}
.element-data{
    display: flex;
    justify-content: flex-start;
}
.element-amount{
    padding-right: 15px;
}
